<template>
<div>

    <v-row justify="center">
        <v-dialog v-model="window" scrollable fullscreen>
           
            <v-card v-if="item">
                <v-card-title style="background-color:#e9f8f2;" class="pa-1">
              
                    {{item.fullname}}
                    
                    <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeWindow">
                       ปิด
                    </v-btn>
                    </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px;">

                    <div class="row">
                        <div class="col-sm-6">
                            <v-card class="mx-auto mt-6" max-width="500" outlined>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5 mb-1">
                                            {{item.title}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div><b>CODE :</b> {{item.code}}</div>
                                        </v-list-item-subtitle>

                                        <v-list-item-subtitle>
                                            <div v-if="item.for_sale=='A'"><b>ขาย :</b> {{item.price}}</div>
                                            <div v-if="item.for_rent=='A'"><b>ให้เช่า :</b> {{item.price_rent}}</div>
                                        </v-list-item-subtitle>

                                    </v-list-item-content>

                                    <v-list-item-avatar tile size="80" color="grey"><img :src="item.picture"></v-list-item-avatar>
                                </v-list-item>

                            </v-card>

                            <v-card class="mx-auto mt-6 pa-5" max-width="500" outlined>

                                <div class="row mb-3">
                                    <div class="col-sm-3"><b>ชื่อ - นามสกุล</b></div>
                                    <div class="col-sm-9"> {{item.fullname}}</div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-sm-3"><b>อีเมล์</b></div>
                                    <div class="col-sm-9">{{item.email}}</div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-sm-3"><b>เบอร์โทร</b></div>
                                    <div class="col-sm-9">{{item.phone }}</div>
                                </div>
 

                                <div class="row mb-3">
                                    <div class="col-sm-3"><b>ข้อความ</b></div>
                                    <div class="col-sm-9" v-html="item.detail"></div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-sm-3"><b>วันที่</b></div>
                                    <div class="col-sm-9">{{item.created}}</div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-sm-3"><b>อัปเดท</b></div>
                                    <div class="col-sm-9">{{item.updated}}</div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-sm-3"><b>สถานะ</b></div>
                                    <div class="col-sm-9">{{item.status_title}}</div>
                                </div>

                            </v-card>
                        </div>
                        <div class="col-sm-6">

                            <ContactStatus :contact_id="contact_id" :item="item" @updateStatus="updateItem()" />

                        </div>
                    </div>

                </v-card-text>
         
           
            </v-card>
        </v-dialog>
    </v-row>

</div>
</template>

<script>
import ContactStatus from '@/views/Contact/ContactStatus'
export default {

    components: {
        ContactStatus
    },
    props: {
        open: false,
        contact_id: null,
    },

    data: () => ({
        window: null,
        item: null

    }),

    computed: {

    },

    watch: {
       contact_id() {
            this.getItem();
        },
        open(val) {
            {
                if (val == true) {
                    this.window = true
                }

            }
        },
    },

    methods: {

         updateItem() {

             this.getItem();
          
            this.$emit('updateItem', true)
        },

        closeWindow() {
            this.window = false
            this.$emit('closeWindows', true)
        },

        getItem() {
            let url = "contact/one?contact_id=" + this.contact_id;
            console.log(url)

            this.loading = true

            let $vm = this;
            this.$http
                .post(url, this.formData())
                .then(function (res) {
                    console.log(res.data);

                    $vm.loading = false
                    $vm.item = res.data.item
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    },
    created() {

    },

};
</script>
