<template>
<div class="pa-4 ListAll">
    <div v-if="!items[0]">
        <div class="coming-soon text-center">ยังไม่มีรายการค่ะ</div>
    </div>
    <div v-if="items[0]">
        <v-data-iterator :items="items" :items-per-page.sync="itemsPerPage" :page="page" :search="search" :sort-by="sortBy.toLowerCase()" :sort-desc="sortDesc" hide-default-footer>
            <template v-slot:header>

                <v-slide-group>

                    <v-slide-item>
                        <v-text-field v-model="search" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="ค้นหา"></v-text-field>
                    </v-slide-item>

                </v-slide-group>

            </template>

            <template v-slot:default="props">

                <v-card v-for="item in props.items" :key="item.contact_id" class="mx-auto mt-5" tile>

                    <v-list-item six-line @click="openDetail(item.contact_id)"  style="background-color:#e9f8f2;">
                        <v-list-item-content>
                            <v-list-item-title style="font-size:1.2rem; color:#266861;font-weight:500" class="mb-2">{{item.fullname}}</v-list-item-title> 

                            <v-list-item-subtitle class="mb-3"><b>วันที่ :</b> {{item.created}} <b>โทร :</b> {{item.phone}} <b>อีเมล์ :</b> {{item.email}}</v-list-item-subtitle>
                            <v-list-item-subtitle class="mb-3">
                                <div class="mb-3">{{item.title}}</div>

                                <div><b>CODE :</b> {{item.code}}</div>

                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mb-3">
                                <div v-if="item.for_sale=='A'"><b>ขาย :</b> {{item.price}}</div>
                                <div v-if="item.for_rent=='A'"><b>ให้เช่า :</b> {{item.price_rent}}</div>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle class="mb-3">
                                {{item.status_title}}
                            </v-list-item-subtitle>

                        </v-list-item-content>
                    </v-list-item>

                </v-card>

            </template>

            <template v-slot:footer class="mt-4 mb-5">
                <div class="p-2">
                    <v-row class="mt-2" align="center" justify="center">
                        <div class="col-sm-6"> <span class="grey--text ml-4">หน้าละ</span>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                                        {{ itemsPerPage }}
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage(number)">
                                        <v-list-item-title>{{ number }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="col-sm-6 text-right"> <span class="mr-4  grey--text">
                                หน้าที่ {{ page }} จาก {{ numberOfPages }}
                            </span>
                            <v-btn depressed color="grey lighten-5" class="mr-1" @click="formerPage">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn depressed color="grey lighten-5" class="ml-1 mr-2" @click="nextPage">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>

                    </v-row>
                </div>
            </template>

        </v-data-iterator>

        <v-progress-circular v-if="loading" indeterminate color="red"></v-progress-circular>
    </div>
    <ContactDetail :open="open" :contact_id="contact_id" @closeWindows="open=false" @updateItem="getItem()" />
</div>
</template>

<script>
import ContactDetail from '@/views/Contact/ContactDetail'
export default {

    components: {
        ContactDetail
    },
    props: {
        propType: null
    },

    data: () => ({
        loading: null,
        open: false,
        contact_id: null,

        itemsPerPageArray: [10, 20, 30],
        search: '',
        filter: {},
        sortDesc: true,
        page: 1,
        itemsPerPage: 12,
        sortBy: 'created',
        keys: [
            'title',
        ],
        items: [],
        itemData: [],
        itemStatus: 'all',
        status_list: null,
        sortByList: [],

    }),

    computed: {
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        },
        filteredKeys() {
            return this.keys.filter(key => key !== 'Name')
        },
    },

    watch: {

    },
    methods: {

        openDetail(contact_id) {
            this.contact_id = contact_id
            this.open = true;
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },

        getItem() {
            let url = "contact/list";
            console.log(url)

            this.loading = true

            let $vm = this;
            this.$http
                .post(url, this.formData())
                .then(function (res) {
                    console.log(res.data);
                    if (res.data.items) {
                        $vm.items = Object.values(res.data.items)

                    }

                    $vm.loading = false
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

    },
    created() {
        this.getItem()

    },
};
</script>
